import React, { useState } from "react";
import Options from "../Compartido/options";
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const Content = ({ lang, unete }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const [empresa, setEmpresa] = useState('');
    const [telefon, setTelefon] = useState('');
    const [email, setEmail] = useState('');
    const [consentimiento, setConsentimiento] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = e.target.action;

        if (empresa === "" || telefon === "" || email === "" || !consentimiento) {
            let mensaje = "";
            if (empresa === "") mensaje += `${t("Empresa")} / ${t('Persona física')}<br/>`;
            if (telefon === "") mensaje += `${t('Teéfono')}<br/>`;
            if (email === "") mensaje += `${t('Email')}<br/>`;
            if (!consentimiento) mensaje += `${t("consentimiento")}<br/>`;
            Swal.fire({
                title: `${t('Error')}`,
                html: `${t('Todos los campos obligatorios')}<br/>` + mensaje,
                timer: 2500,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeClick: false
            });
        } else {
            fetch(url, {
                method: 'GET',
                body: new FormData(e.target),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.type === "error") {
                        Swal.fire({
                            title: "KO!",
                            html: `${t('Contacto no enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeClick: false
                        });
                    } else {
                        Swal.fire({
                            title: "OK!",
                            html: `${t('Contacto enviado')}`,
                            timer: 1500,
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeClick: false
                        }).then((result) => {
                            window.location.reload();
                        });
                    }
                });
        }
    };

    return (
        <div id="Content">
            <div className="clearfix content_wrapper">
                <div className="sections_group">
                    <div className="entry-content">

                        <Options
                            preidioma={preidioma}
                        />

                        <div className="section" style={{ paddingTop: "60px" }}>
                            <div className="clearfix section_wrapper">
                                <div className="clearfix column_attr align_center">
                                    <h6 className="landing4-heading grey" style={{ paddingBottom: "3px", borderBottom: "1px solid #E87C0C", display: "inline-block", borderColor: "#E87C0C" }}>{t("ÚNETE")}</h6>
                                    <hr className="no_line" style={{ margin: "0 auto 15px auto" }} />
                                </div>
                                <div className="column one column_blog">
                                    <div className="clearfix column_attr">
                                        <div className="mb-5 noticia-individual-descripcio-llarga unetetext" dangerouslySetInnerHTML={{ __html: unete.ladesc }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ****** */}
                        <div className="section mcb-section" style={{ paddingTop: "60px", paddingBottom: "80px", backgroundColor: "#f9f9f9" }}>
                            <div className="section_wrapper mcb-section-inner">
                                <div className="clearfix wrap mcb-wrap one valign-top">
                                    <div className="mcb-wrap-inner">
                                        <div className="column mcb-column one column_column">
                                            <div className="clearfix column_attr">
                                                <h6>{t("RELLENA EL FORMULARIO")}</h6>
                                                <h3>{t("Asegúrate de completar los campos marcados con *")}</h3>
                                                <hr className="no_line" style={{ margin: "0 auto 15px" }} />
                                                <div id="contactWrapper">
                                                    <form id="contactForm" action="/enviacontactosocio" onSubmit={handleSubmit}>
                                                        <input type="hidden" name="lang" value={lang.toString().toUpperCase()} />

                                                        <div className="column one-second espai">
                                                            <input placeholder={t("Compañia") + " / " + t("Persona física") + "*"} type="text" name="empresa" id="empresa" size="100" aria-required="true" aria-invalid="false" value={empresa} onChange={(e) => setEmpresa(e.target.value)} />
                                                        </div>
                                                        <div className="column one-second espai">
                                                            <input placeholder={t("Nombre y apellidos")} type="text" name="name" id="nombre" size="100" aria-invalid="false" />
                                                        </div>
                                                        <div className="column one-second espai">
                                                            <input placeholder={t("Teléfono") + " *"} type="text" name="phone" id="phone" size="40" aria-required="true" aria-invalid="false" value={telefon} onChange={(e) => setTelefon(e.target.value)} />
                                                        </div>

                                                        <div className="column one-second espai">
                                                            <input placeholder={t("Email") + " *"} type="email" name="email" id="email" size="100" aria-required="true" aria-invalid="false" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                        </div>

                                                        <div className="mt-20 column one">
                                                            <input id="consentimiento" type="checkbox" name="consentimiento" checked={consentimiento} onChange={(e) => setConsentimiento(e.target.checked)} />{t("He leido y doy")} <a href={preidioma + t('consentimiento-formulario')}> {t('consentimiento')} </a> {t("para el tratamiento de datos")}
                                                        </div>
                                                        <br /><br />
                                                        <div className="column one">
                                                            <input type="submit" value={t("Enviar")} id="submit" />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ****** */}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
