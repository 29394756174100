import React, { useState, useRef, forwardRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import { Accordion } from 'react-bootstrap';
import { Container, Form, Button } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { parseISO } from 'date-fns';
import CustomSelect from '../Compartido/CustomSelect.jsx';

import logo from "/images/magno/LOGO-MAGNO.png";

import "./../../../../../css/magno/buscador.css";


import { useTranslation } from 'react-i18next';

const Buscador = ({ destino, destinos, tiposcasas, adultos, ninos, bebes, llegada, salida, tipocasa, lang, config, filtros, palabra, propiedades, losbarrios, tipoprops, totalapartamentos, ruta }) => {

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    registerLocale('es', es)

    let difdias = " - ";

    let puntos = [];
    let centrolat = 0;
    let centrolong = 0;

    const myIcon = L.icon({
        iconUrl: logo,
        iconSize: [40, 49],
        iconAnchor: [20, 49],
        popupAnchor: [0, -49],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });

    {
        propiedades.forEach((propiedad, index) => {
            centrolat += Number(propiedad.latitud);
            centrolong += Number(propiedad.longitud);
            let foto = propiedad.foto_principal ? ruta + "/propiedades/" + propiedad.id + "/fotos/web/" + propiedad.foto_principal[0].nombre : "";
            let personas = propiedad.capacidad_maxima + " " + t('Personas');
            let metros = propiedad.metros_cuadrados + " " + t('M2');
            let banyos = propiedad.num_banyos + " " + t('Baños');
            let habs = propiedad.num_habitaciones + " " + t('Dormitorios');
            let urlpropiedad = "/" + preidioma + t("apartamentos") + "-" + propiedad.destino.url_dinamica[lang] + "/" + propiedad.url_dinamica;

            puntos = puntos.concat(
                <Marker key={"ma" + index} position={[propiedad.latitud, propiedad.longitud]} icon={myIcon}>
                    <Popup>
                        <p>
                            <img src={foto} style={{ width: "220px" }} />
                        </p>
                        <h3 style={{ marginBottom: "18px", fontWeight: "800", fontSize: "1.17em", marginTop: "7px", display: "block", marginBlockStart: "1em", marginBlockEnd: "1em", marginInlineStart: "0px", marginInlineEnd: "0px", textTransform: "uppercase", textAlign: "center" }}>{propiedad.nombre}</h3>
                        <p style={{ marginTop: "-2px", fontWeight: "500", color: "#C19B77", textAlign: "center", fontFamily: "'Lato', sans-serif", fontSize: "12px", lineHeight: "15px" }}>{personas + " - " + metros + " - " + banyos + " - " + habs}</p>
                        <p style={{ marginTop: "3px", backgroundColor: "grey", lineHeight: "15px" }}>
                            <hr style={{ borderTop: "1px solid rgba(0,0,0,0.4)" }} />
                        </p>
                        <p style={{ marginTop: "3px", marginBottom: "40px", marginTop: "30px", textAlign: "center", lineHeight: "15px" }}>
                            <a href={urlpropiedad} className="Reserva" target="_blank">{t("RESERVA")}</a>
                        </p>
                    </Popup>
                </Marker>
            )
        })
    }

    if (propiedades.length > 0) {
        centrolat = Number(centrolat) / Number(propiedades.length);
        centrolong = Number(centrolong) / Number(propiedades.length);
    }

    let resultsd = [];
    let optionchecked = t("Selecciona alojamiento");

    let todos = t("Todos");

    let objeto0 = { id: -1, name: todos, value: 0 }
    resultsd = resultsd.concat(objeto0);

    {
        destinos.forEach((undestino, index) => {
            let objeto = { id: index, name: undestino.elnombre, value: undestino.id }
            if (undestino.id == destino) optionchecked = undestino.elnombre;
            resultsd = resultsd.concat(objeto);
        })
    }

    let inicioreserva = null;
    let finalreserva = null;

    if (llegada && salida) {
        var fechaInicio = new Date(llegada).getTime();
        var fechaFin = new Date(salida).getTime();

        var diff = fechaFin - fechaInicio;

        difdias = diff / (1000 * 60 * 60 * 24);

        inicioreserva = new Date(llegada);
        finalreserva = new Date(salida);
    }

    const [startDate, setStartDate] = useState(inicioreserva);

    const ExampleCustomInputEntrada = forwardRef(({ value, onClick }, ref) => (
        <>
            <div onClick={onClick} ref={ref}>
                <div className="nd_booking_float_left nd_booking_text_align_right">
                    <h1 id="nd_booking_date_number_to_front" className="nd_booking_font_size_50 nd_booking_color_yellow_important colorcorporativo">
                        {value != "" ? ("0" + new Date(value).getDate()).slice(-2) : "- -"}
                        <sup style={{ textTransform: "capitalize", fontFamily: "Lato", fontWeight: "350", paddingRight: "6px", fontSize: "12px" }}>
                            {value != "" ? new Date(value).toLocaleString('es-ES', { month: 'short' }) : ""}
                        </sup>
                        {
                            value != "" ?
                                <img src="/images/magno/down_icon.svg" width="10" style={{ marginLeft: "-20px" }} />
                                :
                                <img src="/images/magno/down_icon.svg" width="10" />
                        }
                    </h1>
                </div>
                <input type="hidden" id="fechaentrada" defaultValue={value} />
            </div>
        </>
    ));

    const [endDate, setEndDate] = useState(finalreserva);

    const ExampleCustomInputSalida = forwardRef(({ value, onClick }, ref) => (
        <>
            <div onClick={onClick} ref={ref}>
                <div className="nd_booking_float_left nd_booking_text_align_right">
                    <h1 id="nd_booking_date_number_to_front" className="nd_booking_font_size_50 nd_booking_color_yellow_important colorcorporativo">
                        {value != "" ? ("0" + new Date(value).getDate()).slice(-2) : "- -"}
                        <sup style={{ textTransform: "capitalize", fontFamily: "Lato", fontWeight: "350", paddingRight: "6px", fontSize: "12px" }}>
                            {value != "" ? new Date(value).toLocaleString('es-ES', { month: 'short' }) : ""}
                        </sup>
                        {
                            value != "" ?
                                <img src="/images/magno/down_icon.svg" width="10" style={{ marginLeft: "-20px" }} />
                                :
                                <img src="/images/magno/down_icon.svg" width="10" />
                        }
                    </h1>
                </div>
                <input type="hidden" id="fechasalida" defaultValue={value} />
            </div>
        </>
    ));


    const cambioFechas = (date) => {
        setStartDate(date);
        setEndDate(date);
    };

    const cambioFechas2 = (date) => {
        setEndDate(date);
        var fechaInicio = new Date(startDate).getTime();
        var fechaFin = new Date(date).getTime();

        var diff = fechaFin - fechaInicio;

        difdias = diff / (1000 * 60 * 60 * 24);

        document.getElementById("difdias").innerHTML = difdias;

    };

    {/* ************* */ }

    const [show, setShow] = useState('none');
    const handleShow = () => {
        if (show == 'none') setShow('block');
        else setShow('none');
    }

    const onClickOutsideListener = () => {
        handleShow()
        document.removeEventListener("click", onClickOutsideListener)
    }

    let textopersonas = "";

    if (adultos > 0 || ninos > 0 || bebes > 0) {
        textopersonas = Number(adultos) + Number(ninos) + Number(bebes);
    }

    const numpersonas = useRef(null);

    const [countAdults, setCountAdults] = useState(parseInt(adultos));
    const [countNinos, setCountNinos] = useState(parseInt(ninos));
    const [countBebes, setCountBebes] = useState(parseInt(bebes));

    const handleSumaAdultos = () => {

        if (countAdults < 10) {
            setCountAdults(ca => ca + 1);
            handleEtiqueta(parseInt(countAdults) + 1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleRestaAdultos = () => {

        if (countAdults > 0) {
            setCountAdults(ca => ca - 1);
            handleEtiqueta(parseInt(countAdults) - 1, parseInt(countNinos), parseInt(countBebes));
        }
    }

    const handleSumaNinos = () => {
        if (countNinos < 10) {
            setCountNinos(cn => cn + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos) + 1, parseInt(countBebes));
        }
    }

    const handleRestaNinos = () => {
        if (countNinos > 0) {
            setCountNinos(cn => cn - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos) - 1, parseInt(countBebes));
        }
    }

    const handleSumaBebes = () => {
        if (countBebes < 10) {
            setCountBebes(cb => cb + 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes) + 1);
        }
    }

    const handleRestaBebes = () => {
        if (countBebes > 0) {
            setCountBebes(cb => cb - 1);
            handleEtiqueta(parseInt(countAdults), parseInt(countNinos), parseInt(countBebes) - 1);
        }
    }

    const handleEtiqueta = (nadults, nninos, nbebes) => {

        let frase = Number(nadults) + Number(nninos) + Number(nbebes)

        numpersonas.current.textContent = frase;
    }

    {/* ************* */ }

    const handleBuscar = () => {
        var llegada = document.getElementById("fechaentrada").value;
        var salida = document.getElementById("fechasalida").value;
        var adultos = document.getElementById("numadultos").value;
        var ninos = document.getElementById("numninos").value;
        var bebes = document.getElementById("numbebes").value;

        var destino = document.getElementById("destinoid").value;

        var nombreprop = document.getElementById("nombreprop").value;

        var filtros0 = [];
        var eltipocasa = [];

        var lamarca = [];

        var tipoprops0 = [];

        var checkboxes = document.getElementsByName('filter_amenities[]');
        for (var checkbox of checkboxes) {
            if (checkbox.checked) {
                filtros0.push(checkbox.value);
            }
        }

        var tipocasas = document.getElementsByName("tipocasa");
        for (var untipocasa of tipocasas) {
            if (untipocasa.checked) {
                eltipocasa.push(untipocasa.value);
            }
        }

        var marcas = document.getElementsByName("barrio");
        for (var unamarca of marcas) {
            if (unamarca.checked) {
                lamarca.push(unamarca.value);
            }
        }

        var idPropiedades = document.getElementsByName("idPropiedades");
        for (var unidpropiedades of idPropiedades) {
            if (unidpropiedades.checked) {
                tipoprops0.push(unidpropiedades.value);
            }
        }




        var form = document.createElement('form');

        form.method = 'GET';
        form.action = "/" + preidioma + t("busca")

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'entrada';
        input.value = llegada;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'salida';
        input.value = salida;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'adultos';
        input.value = adultos;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'ninos';
        input.value = ninos;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'bebes';
        input.value = bebes;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'destino';
        input.value = destino;
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'filter_amenities';
        input.value = JSON.stringify(filtros0);
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'tipocasa';
        input.value = JSON.stringify(eltipocasa);
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'barrio';
        input.value = JSON.stringify(lamarca);
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'tipoprops';
        input.value = JSON.stringify(tipoprops0);
        form.appendChild(input);

        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'nombreprop';
        input.value = nombreprop;
        form.appendChild(input);



        document.body.append(form);

        form.submit();

    }

    {/* ************* */ }

    return (
        <div className="order-1 col-lg-4 order-lg-2 w400-bk">
            <div className="vc_column-inner vc_custom_1676226857229">
                <div className="wpb_wrapper">
                    <div className="row">
                        <div className="mt-2 col-md-12">
                            <div className="vc_column-inner">
                                <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                        <div className="wpb_wrapper">

                                            <div className="nd_booking_section nd_booking_padding_15 nd_booking_padding_0_responsive nd_booking_box_sizing_border_box" style={{ backgroundColor: "#151515", paddingBottom: "25px" }}>
                                                <div id="nd_booking_search_main_bg" className="row">
                                                    <div className="col-md-12 col-12" style={{ textAlign: "center", marginTop: "30px" }}>
                                                        <CustomSelect
                                                            defaultText={optionchecked}
                                                            optionsList={resultsd}
                                                            nameInput="destino"
                                                            realInput={destino}
                                                            nameId="destinoid"
                                                        />
                                                    </div>
                                                    <div className="col-md-12 row" style={{ textAlign: "center", marginLeft: "-6px" }}>
                                                        <div className="col-md-1 col-1"></div>
                                                        <div className="mt-3 mb-3 col-md-5 col-5 pad-25 mr-15 datacercador" style={{ background: "#000", paddingBottom: "18px" }}>
                                                            <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12" style={{ color: "#FFF", fontFamily: 'Lato', fontWeight: "350", letterSpacing: "2px", fontSize: "12px", lineHeight: "12px", paddingTop: "10px" }}>{t("Entrada").toUpperCase()}</h6>
                                                            <DatePicker
                                                                selected={startDate}
                                                                onChange={(date) => cambioFechas(date)}
                                                                dateFormat="MM/dd/yyyy"
                                                                minDate={new Date()}
                                                                locale={lang}
                                                                customInput={<ExampleCustomInputEntrada />}
                                                                popperClassName="centrada"
                                                                popperPlacement="bottom-start"
                                                                popperModifiers={{
                                                                    flip: {
                                                                        behavior: ["bottom-start"] // don't allow it to flip to be above
                                                                    },
                                                                    preventOverflow: {
                                                                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                    },
                                                                    hide: {
                                                                        enabled: false // turn off since needs preventOverflow to be enabled
                                                                    }
                                                                }}
                                                            />
                                                            <input type="hidden" id="nd_booking_date_month_from" className="nd_booking_section nd_booking_margin_top_20" />
                                                            <input type="hidden" id="nd_booking_date_number_from" className="nd_booking_section nd_booking_margin_top_20" />
                                                        </div>

                                                        <div className="mt-3 mb-3 col-md-5 col-5 pad-25 datacercador" style={{ background: "#000", paddingBottom: "18px" }}>
                                                            <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12" style={{ color: "#FFF", fontFamily: 'Lato', fontWeight: "350", letterSpacing: "2px", fontSize: "12px", lineHeight: "12px", paddingTop: "10px" }}>{t("Salida").toUpperCase()}</h6>
                                                            <DatePicker
                                                                selected={endDate}
                                                                onChange={(date) => cambioFechas2(date)}
                                                                dateFormat="MM/dd/yyyy"
                                                                minDate={new Date()}
                                                                locale={lang}
                                                                customInput={<ExampleCustomInputSalida />}
                                                                popperClassName="csalida"
                                                                popperPlacement="bottom-end"
                                                                popperModifiers={{
                                                                    flip: {
                                                                        behavior: ["bottom"] // don't allow it to flip to be above
                                                                    },
                                                                    preventOverflow: {
                                                                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                    },
                                                                    hide: {
                                                                        enabled: false // turn off since needs preventOverflow to be enabled
                                                                    }
                                                                }}
                                                            />
                                                            <input type="hidden" id="nd_booking_date_month_to" className="nd_booking_section nd_booking_margin_top_20" />
                                                            <input type="hidden" id="nd_booking_date_number_to" className="nd_booking_section nd_booking_margin_top_20" />
                                                        </div>
                                                        <div className="col-md-1 col-1"></div>
                                                    </div>

                                                    <div className="col-md-12 row" style={{ textAlign: "center", marginLeft: "-6px" }}>
                                                        <div className="col-md-1 col-1"></div>
                                                        <div className="mt-3 mb-3 col-md-5 col-5 pad-25 mr-15" style={{ background: "#000" }}>
                                                            <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12" style={{ color: "#FFF", fontFamily: 'Lato', fontWeight: "350", letterSpacing: "2px", fontSize: "12px", lineHeight: "12px" }}>{t("Personas").toUpperCase()}</h6>
                                                            <div className="nd_booking_float_left nd_booking_text_align_right">
                                                                <h1 className="nd_booking_font_size_50 nd_booking_color_yellow_important nd_booking_guests_number colorcorporativo" onClick={handleShow} ref={numpersonas}>{textopersonas}
                                                                </h1>
                                                                <img decoding='async' className='nd_booking_float_right nd_booking_guests_decrease nd_booking_cursor_pointer' alt='' width='10' src='/images/magno/down_icon.svg' style={{ position: "absolute", marginTop: "-40px", marginLeft: "15px" }} />
                                                            </div>

                                                            {/* ************ */}

                                                            <div className="formpersonas" style={{ display: show }} tabIndex="0" onMouseLeave={() => { document.addEventListener("click", onClickOutsideListener) }}>
                                                                <div className="mt-2 row">
                                                                    <label className="col-md-6 col-6 etiqueta-formulari" style={{ fontSize: "85%", lineHeight: "17px", color: "#a6a6a6", textTransform: "none", fontFamily: "Lato, sans-serif", width: "50%", textAlign: "left" }}> {t("Adultos")}<div className="mini-etiqueta-formulari">({t("Desde_12_años")})</div></label>
                                                                    <div className="col-md-5 col-6">
                                                                        <div className="mt-3 input-group">
                                                                            <span className="input-group-btn">
                                                                                <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_adultos" onClick={handleRestaAdultos}>
                                                                                    <span className="centrar-boto-cercle">
                                                                                        <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                                    </span>
                                                                                </button>
                                                                            </span>
                                                                            <input type="text" name="adultos" className="form-control input-number numero-persones" value={countAdults} min="0" max="10" id="numadultos" readOnly={true} />
                                                                            <span className="input-group-btn">
                                                                                <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_adultos" onClick={handleSumaAdultos}>
                                                                                    <span className="centrar-boto-cercle">
                                                                                        <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }} >
                                                                                            <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-2 row">
                                                                    <label className="col-md-6 col-6 etiqueta-formulari" style={{ fontSize: "85%", lineHeight: "17px", color: "#a6a6a6", textTransform: "none", fontFamily: "Lato, sans-serif", width: "50%", textAlign: "left" }}> {t("Niños")}<div className="mini-etiqueta-formulari">({t("De_2_a_12_años")})</div></label>
                                                                    <div className="col-md-5 col-6">
                                                                        <div className="mt-3 input-group">
                                                                            <span className="input-group-btn">
                                                                                <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="minus" data-field="my_multi_select_ninos" onClick={handleRestaNinos}>
                                                                                    <span className="centrar-boto-cercle">
                                                                                        <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}><rect height="2" rx="1" width="12" x="6" y="11"></rect></svg>
                                                                                    </span>
                                                                                </button>
                                                                            </span>
                                                                            <input type="text" name="ninos" className="form-control input-number numero-persones" value={countNinos} min="0" max="10" id="numninos" readOnly={true} />
                                                                            <span className="input-group-btn">
                                                                                <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_ninos" onClick={handleSumaNinos}>
                                                                                    <span className="centrar-boto-cercle">
                                                                                        <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                                            <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-2 row">
                                                                    <label className="col-md-6 col-6 etiqueta-formulari" style={{ fontSize: "85%", lineHeight: "17px", color: "#a6a6a6", textTransform: "none", fontFamily: "Lato, sans-serif", width: "50%", textAlign: "left" }}> {t("Bebés")}<div className="mini-etiqueta-formulari">({t("De 0 a 2 años")})</div></label>
                                                                    <div className="col-md-5 col-6">
                                                                        <div className="mt-3 input-group">
                                                                            <span className="input-group-btn">
                                                                                <button type="button" className="btn btn-xs btn-number boto-cercle" datatype="minus" datafield="my_multi_select_bebes" onClick={handleRestaBebes}>
                                                                                    <span className="centrar-boto-cercle">
                                                                                        <svg viewBox="0 0 24 24" role="img" aria-label="subtract" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                                            <rect height="2" rx="1" width="12" x="6" y="11"></rect>
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </span>
                                                                            <input type="text" name="bebes" className="form-control input-number numero-persones" value={countBebes} min="0" max="10" id="numbebes" readOnly={true} />
                                                                            <span className="input-group-btn">
                                                                                <button type="button" className="btn btn-xs btn-number boto-cercle" data-type="plus" data-field="my_multi_select_bebes" onClick={handleSumaBebes}>
                                                                                    <span className="centrar-boto-cercle">
                                                                                        <svg viewBox="0 0 24 24" role="img" aria-label="add" focusable="false" style={{ height: "1em", width: "1em", display: "block", fill: "currentcolor" }}>
                                                                                            <rect height="2" rx="1" width="12" x="6" y="11"></rect><rect height="12" rx="1" width="2" x="11" y="6"></rect>
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* ************ */}
                                                        </div>
                                                        <div className="mt-3 mb-3 col-md-5 col-5 pad-25" style={{ background: "#000" }}>
                                                            <h6 className="nd_options_color_white nd_options_second_font nd_booking_letter_spacing_2 nd_booking_font_size_12" style={{ color: "#FFF", fontFamily: 'Lato', fontWeight: "350", letterSpacing: "2px", fontSize: "12px", lineHeight: "12px" }}>{t("Noches").toUpperCase()}</h6>
                                                            <div className="nd_booking_section nd_booking_height_15"></div>
                                                            <div className="nd_booking_display_inline_flex ">
                                                                <div className="nd_booking_float_left nd_booking_text_align_right">
                                                                    <h1 className="nd_booking_font_size_50 nd_booking_color_yellow_important nd_booking_nights_number colorcorporativo" id="difdias">
                                                                        {difdias}
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 col-1"></div>
                                                    </div>

                                                    <div className="mb-3 col-md-12" style={{ textAlign: "center", marginTop: "15px" }}>
                                                        <button type='button' id='buscador' className='ubtn bgcolorcorporativo btn-reservar-buscar' style={{ color: "#FFF", width: "fit-content", border: "0", borderRadius: "0px", padding: "7px 12px!important" }} onClick={handleBuscar}>
                                                            <span className='ubtn-hover'></span>
                                                            <span className='ubtn-data ubtn-text '>{t("Buscar")}</span>
                                                        </button>
                                                    </div>

                                                    <div className="col-md-12" style={{ textAlign: "center", marginTop: "15px", marginBottom: "15px" }}>
                                                        <form className="elementor-search-form buscanombre" role="search" action={"/" + preidioma + t("busca")} method="get" autoComplete="off">
                                                            <div className="elementor-search-form__container botonbuscaporalojamiento">
                                                                <div className="elementor-search-form__icon">
                                                                    <button type="submit" style={{ background: "none", border: "0" }}>
                                                                        <i aria-hidden="true" className="fas fa-search buscalupa"></i>
                                                                    </button>
                                                                    <input id="nombreprop" placeholder={t("BUSCAR POR ALOJAMIENTO")}
                                                                        style={{ fontFamily: "'Roboto', sans-serif", fontSize: "14px", fontWeight: "400", border: "0", outline: "0px", background: "#FFF", width: "70%" }}
                                                                        className="elementor-search-form__input inputnombreprop" type="search" name="nombreprop" title={t("Buscar")} defaultValue={palabra} autoComplete="off" />
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="vc_column-inner vc_custom_1688745970303">
                                <div className="wpb_wrapper">
                                    {/*<p style={{ color: "#c19b77", textAlign: "left", fontFamily: "Lato", fontWeight: "400", fontStyle: "normal", fontSize: "17px", marginBottom: "16px", marginTop: "10px" }}> {t("Se han encontrado") + " " + totalapartamentos + " " + t("Alojamientos")}</p>*/}
                                    <h4 className="mt-4 vc_custom_heading vc_custom_1688726795061" style={{ color: "#000000", textAlign: "left", fontFamily: "Lato", fontWeight: "400", fontStyle: "normal", fontSize: "17px", lineHeight: "17px" }}>{t("Filtrar por")}:</h4>
                                    <div className="vc_toggle vc_toggle_simple vc_toggle_color_default vc_toggle_size_md vc_custom_1688745895275">

                                        <Accordion>
                                            <Accordion.Item eventKey="Servicios">
                                                <Accordion.Header style={{ fontSize: "16px", textAlign: "left", fontFamily: "Lato", fontWeight: "300", fontStyle: "normal" }}>{t("Servicios").toUpperCase()}</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ fontFamily: "'Roboto', sans-serif", paddingTop: "0px", fontSize: "14px", lineHeight: "27px", color: "#5c5c5c", paddingLeft: "30px" }}>
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10020" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10020) != -1} onChange={handleBuscar} /> {t("Piscina")}<br />
                                                            </>

                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10015" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10015) != -1} onChange={handleBuscar} /> {t("Jacuzzi")}<br />
                                                            </>
                                                        }
                                                        {

                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10016" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10016) != -1} onChange={handleBuscar} /> {t("Terraza")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10017" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10017) != -1} onChange={handleBuscar} /> {t("Balcón")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10018" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10018) != -1} onChange={handleBuscar} /> {t("Recepción física")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10013" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10013) != -1} onChange={handleBuscar} /> {t("Gimnasio")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10019" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10019) != -1} onChange={handleBuscar} /> {t("Ascensor")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="1298" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(1298) != -1} onChange={handleBuscar} /> {t("Caja fuerte")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10006" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10006) != -1} onChange={handleBuscar} /> {t('Adaptado a personas con movilidad reducida')}<br />
                                                            </>
                                                        }
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                    </div>
                                    <div className="vc_toggle vc_toggle_simple vc_toggle_color_default vc_toggle_size_md vc_custom_1688745757957">
                                        <Accordion>
                                            <Accordion.Item eventKey="Premium">
                                                <Accordion.Header style={{ fontSize: "16px", textAlign: "left", fontFamily: "Lato", fontWeight: "300", fontStyle: "normal" }}>{t('SERVICIOS PREMIUM')}</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ fontFamily: "'Roboto', sans-serif", paddingTop: "0px", fontSize: "14px", lineHeight: "27px", color: "#5c5c5c", paddingLeft: "30px" }}>
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10004" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10004) != -1} onChange={handleBuscar} /> {t("Parking privado")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="1068" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(1068) != -1} onChange={handleBuscar} /> {t("Piscina privada")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10000" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10000) != -1} onChange={handleBuscar} /> {t("Jacuzzi privado")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10002" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10002) != -1} onChange={handleBuscar} /> {t("Terraza privada")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10001" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10001) != -1} onChange={handleBuscar} /> {t("Consigna")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="10005" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(10005) != -1} onChange={handleBuscar} /> {t("Vistas de interés")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="1459" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(1459) != -1} onChange={handleBuscar} /> {t("Netflix")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="filter_amenities[]" id="" defaultValue="1464" form="laBusqueda" className="amenities" defaultChecked={filtros.indexOf(1464) != -1} onChange={handleBuscar} /> {t("Entrada autónoma")}<br />
                                                            </>
                                                        }
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <div className="vc_toggle vc_toggle_simple vc_toggle_color_default vc_toggle_size_md vc_custom_1688745766479">
                                        <Accordion>
                                            <Accordion.Item eventKey="Tipologia">
                                                <Accordion.Header style={{ fontSize: "16px", textAlign: "left", fontFamily: "Lato", fontWeight: "300", fontStyle: "normal" }} >{t('TIPOLOGÍA')}</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ fontFamily: "'Roboto', sans-serif", paddingTop: "0px", fontSize: "14px", lineHeight: "27px", color: "#5c5c5c", paddingLeft: "30px" }}>
                                                        {
                                                            <>
                                                                <input type="checkbox" name="tipocasa" id="" defaultValue="1" defaultChecked={tipocasa.indexOf(1) != -1} form="laBusqueda" className="tipocasa" onChange={handleBuscar} /> {t("Apartamento")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="tipocasa" id="" defaultValue="5" defaultChecked={tipocasa.indexOf(5) != -1} form="laBusqueda" className="tipocasa" onChange={handleBuscar} /> {t("Casa")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="tipocasa" id="" defaultValue="4" defaultChecked={tipocasa.indexOf(4) != -1} form="laBusqueda" className="tipocasa" onChange={handleBuscar} /> {t("Estudios")}<br />
                                                            </>
                                                        }
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <div className="vc_toggle vc_toggle_simple vc_toggle_color_default vc_toggle_size_md vc_custom_1688745775521">
                                        <Accordion>
                                            <Accordion.Item eventKey="Zona">
                                                <Accordion.Header style={{ fontSize: "16px", textAlign: "left", fontFamily: "Lato", fontWeight: "300", fontStyle: "normal" }}>{t('ZONA')}</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ fontFamily: "'Roboto', sans-serif", paddingTop: "0px", fontSize: "14px", lineHeight: "27px", color: "#5c5c5c", paddingLeft: "30px" }}>
                                                        {
                                                            <>
                                                                <input type="checkbox" name="barrio" id="" defaultValue="1" defaultChecked={losbarrios.indexOf(1) != -1} form="laBusqueda" className="barrio" onChange={handleBuscar} /> Centro histórico<br />
                                                            </>

                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="barrio" id="" defaultValue="2" defaultChecked={losbarrios.indexOf(2) != -1} form="laBusqueda" className="barrio" onChange={handleBuscar} /> Zona Alameda<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="barrio" id="" defaultValue="3" defaultChecked={losbarrios.indexOf(3) != -1} form="laBusqueda" className="barrio" onChange={handleBuscar} /> Zona Triana
                                                            </>

                                                        }
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <div className="vc_toggle vc_toggle_simple vc_toggle_color_default vc_toggle_size_md vc_custom_1688745908708">
                                        <Accordion>
                                            <Accordion.Item eventKey="Habitaciones">
                                                <Accordion.Header style={{ fontSize: "16px", textAlign: "left", fontFamily: "Lato", fontWeight: "300", fontStyle: "normal" }} >{t('HABITACIONES')}</Accordion.Header>
                                                <Accordion.Body>
                                                    <p style={{ fontFamily: "'Roboto', sans-serif", paddingTop: "0px", fontSize: "14px", lineHeight: "27px", color: "#5c5c5c", paddingLeft: "30px" }}>
                                                        {
                                                            <>
                                                                <input type="checkbox" name="idPropiedades" id="1" defaultValue="1" form="laBusqueda" defaultChecked={tipoprops.indexOf(1) != -1} className="habs" onChange={handleBuscar} /> 1 {t("Habitación")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="idPropiedades" id="2" defaultValue="2" form="laBusqueda" defaultChecked={tipoprops.indexOf(2) != -1} className="habs" onChange={handleBuscar} /> 2 {t("Habitaciones")}<br />
                                                            </>
                                                        }
                                                        {
                                                            <>
                                                                <input type="checkbox" name="idPropiedades" id="3" defaultValue="3" form="laBusqueda" defaultChecked={tipoprops.indexOf(3) != -1} className="habs" onChange={handleBuscar} /> +3 {t("Habitaciones")}<br />
                                                            </>
                                                        }
                                                    </p>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row solodesktop">
                        <div className="col-md-12">
                            <div className="vc_column-inner vc_custom_1675194446775">
                                <div className="wpb_wrapper">
                                    <div className="wpb_raw_code wpb_content_element wpb_raw_html">
                                        <div className="wpb_wrapper">
                                            <div id="map" style={{ width: "100%", height: "700px" }}>
                                                <MapContainer center={[centrolat, centrolong]} zoom={14} scrollWheelZoom={false}>
                                                    <TileLayer
                                                        attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                                                        url="https://api.mapbox.com/styles/v1/magnoapartments/clm9hgl2p015701pjd18p6vrv/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFnbm9hcGFydG1lbnRzIiwiYSI6ImNsY29uNmx4bjAwMWszdm4yYmY5aDR5YngifQ.6pEwCqEnsjUoXQQxu-Tcng"
                                                    />
                                                    {puntos}
                                                </MapContainer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Buscador;
