import { React, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Container } from "react-bootstrap";
//import { right } from "@popperjs/core";
import CustomAlert from "./CustomAlert";
import ReactHtmlParser from 'react-html-parser';

import "./../../../../../css/magno/footer.css";

import axios from 'axios';


const Footer = ({ lang, config, config2, idiomas, ruta }) => {

    const { t } = useTranslation();

    const [alert, setAlert] = useState({ show: false, type: '', message: '' });


    const handleLangSelect = (currentLang) => {

        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/cambiaidioma', {
            lang: currentLang
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                console.log(response.data);
                console.log("cambiado voy a recargar");
                window.location.reload();
            })
            .catch((error) => {
                console.log("Error");
                console.log(error);
            });
    }

    const enviaMailchmp = (e) => {
        e.preventDefault()
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/enviamailchimp', {
            email: e.target.email.value
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                if (response.data.type == 'success') {
                    setAlert({ show: true, type: 'ok', message: t("Contacto enviado. En breve nos pondremos en contacto con Ud.") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);

                    location.reload();
                }
                else {
                    setAlert({ show: true, type: 'ko', message: t("Contacto no enviado") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);
                }
            });
    }

    const handleCloseAlert = () => {
        setAlert({ show: false, type: '', message: '' });
    };

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    var results = []

    return (
        <>
            <footer className={"footer bg-black"} >
                <Container className="contfooter" style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <div className="row espaciofooter" style={{ color: "#FFFFFF", fontFamily: "'Lato', Sans-serif", fontSize: "12px" }}>
                        <div className="col-md-3 solodesktopfooter w21pc" >
                            <div className="elementor-widget-container">
                                <a href={"/".preidioma}>
                                    <img decoding="async" src={ruta + "/web/logo/" + config2.logo_fiscal} className="attachment-full size-full wp-image-9390" alt="" loading="lazy" style={{ width: "88%", marginLeft: "-16px" }} />
                                </a>
                            </div>
                            <div className="pt-3 elementor-element elementor-element-7efcb3e elementor-tablet-align-center elementor-list-item-link-inline elementor-align-left elementor-widget-mobile__width-inherit elementor-icon-list--layout-traditional elementor-widget elementor-widget-icon-list" data-id="7efcb3e" data-element_type="widget" data-widget_type="icon-list.default">
                                <div className="elementor-widget-container">
                                    <ul className="elementor-icon-list-items" style={{ padding: "0" }}>
                                        <li className="elementor-icon-list-item" style={{ paddingBottom: "calc(7px / 2)" }}>
                                            <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{config.nombre_comercial + " " + config.nif_fiscal}</span>
                                        </li>
                                        <li className="elementor-icon-list-item" style={{ paddingBottom: "calc(7px / 2)" }}>
                                            <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>© Copyright {new Date().getFullYear()}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-12 col-sm-12 col-lg-12 centradomobile w17pc">
                            <div className="pb-2 elementor-element elementor-element-34ef2ca elementor-widget elementor-widget-text-editor margB-600" data-id="34ef2ca" data-element_type="widget" data-widget_type="text-editor.default" style={{ marginBottom: "22px" }}>
                                <div className="elementor-widget-container">
                                    <strong><span className="colorcorporativo footer-titles" style={{ textTransform: "uppercase", fontWeight: 700 }}>{t('Información')}</span></strong>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-4b2558c elementor-tablet-align-center elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="4b2558c" data-element_type="widget" data-widget_type="icon-list.default">
                                <div className="elementor-widget-container">
                                    <ul className="elementor-icon-list-items" style={{ padding: "0" }}>
                                        <li className="elementor-icon-list-item" style={{ paddingBottom: "calc(10px/2)" }}>
                                            <a href={"/" + preidioma + t('faqs')} style={{ lineHeight: "20px" }}>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>FAQ's</span>
                                            </a>
                                        </li>
                                        <li className="elementor-icon-list-item" style={{ paddingBottom: "calc(10px/2)" }}>
                                            <a href={"/" + preidioma + t('blog')} style={{ lineHeight: "20px" }}>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{t('Blog')}</span>
                                            </a>
                                        </li>
                                        <li className="elementor-icon-list-item" style={{ paddingBottom: "calc(10px/2)" }}>
                                            <a href={"/" + preidioma + t('experiencias-sevilla')} style={{ lineHeight: "20px" }}>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{t("Experiencias")}</span>
                                            </a>
                                        </li>
                                        <li className="elementor-icon-list-item" style={{ paddingBottom: "calc(10px/2)" }}>
                                            <a href="https://magnoinvestments.es/" target="_blank" style={{ lineHeight: "20px" }}>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>Magno Investments</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-12 col-sm-12 col-lg-12 centradomobile w18pc">
                            <div className="pb-2 elementor-element elementor-element-f54faa3 elementor-widget elementor-widget-text-editor margB-600" data-id="f54faa3" data-element_type="widget" data-widget_type="text-editor.default" style={{ marginBottom: "22px" }}>
                                <div className="elementor-widget-container">
                                    <strong><span className="colorcorporativo footer-titles" style={{ textTransform: "uppercase", fontWeight: 700 }}>{t('usuarios')}</span></strong>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-351910c elementor-tablet-align-center elementor-align-left elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="351910c" data-element_type="widget" data-widget_type="icon-list.default">
                                <div className="elementor-widget-container">
                                    <ul className="elementor-icon-list-items" style={{ padding: "0" }}>
                                        <li className="elementor-icon-list-item" style={{ justifyContent: "flex-start", paddingBottom: "calc(10px/2)" }}>
                                            <a href={"/" + preidioma + t('propietarios')} style={{ lineHeight: "20px" }}>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{t('Ser propietario')}</span>
                                            </a>
                                        </li>
                                        <li className="elementor-icon-list-item" style={{ justifyContent: "flex-start", paddingBottom: "calc(10px/2)" }}>
                                            <a href="/intranet" style={{ lineHeight: "20px" }}>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{t('Login de propietarios')}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-12 col-sm-12 col-lg-12 centradomobile w20pc">
                            <div className="pb-2 elementor-element elementor-element-b4402df elementor-widget elementor-widget-text-editor margB-600" data-id="b4402df" data-element_type="widget" data-widget_type="text-editor.default" style={{ marginBottom: "22px" }}>
                                <div className="elementor-widget-container">
                                    <strong><span className="colorcorporativo footer-titles" style={{ textTransform: "uppercase", fontWeight: 700 }}>{t('Contacto')}</span></strong>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-5647af2 elementor-widget elementor-widget-text-editor" data-id="5647af2" data-element_type="widget" data-widget_type="text-editor.default">
                                <div className="pb-3 elementor-widget-container">
                                    <span className="solodesktopfooter" style={{ fontWeight: "normal", lineHeight: "25px", fontSize: "12px" }}>
                                        {ReactHtmlParser(t('De lunes a viernes de 9:00 a<br/> 14:00h y de 15:00 a 18:00h'))}
                                    </span>
                                    <span className="solomobilefooter" style={{ fontWeight: "normal", lineHeight: "22px" }}>{t('De lunes a viernes')}<br />{t('de 9:00 a 14:00h y de 15:00 a 18:00h')}</span>
                                </div>
                            </div>

                            <div className="elementor-element elementor-element-7d2aa33 elementor-tablet-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="7d2aa33" data-element_type="widget" data-widget_type="icon-list.default">
                                <div className="elementor-widget-container">
                                    <ul className="elementor-icon-list-items" style={{ padding: "0" }}>
                                        <li className="elementor-icon-list-item" style={{ justifyContent: "flex-start" }}>
                                            <a href={"tel:" + config.telefono_fiscal}>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{config.telefono_fiscal}</span>
                                            </a>
                                        </li>
                                        <li className="elementor-icon-list-item" style={{ justifyContent: "flex-start" }}>
                                            <a href={"mailto:" + config.email_fiscal}>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{config.email_fiscal}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="elementor-element elementor-element-a84beec elementor-tablet-align-center elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="a84beec" data-element_type="widget" data-widget_type="icon-list.default">
                                <div className="elementor-widget-container">
                                    <ul className="elementor-icon-list-items" style={{ padding: "0" }}>
                                        <li className="elementor-icon-list-item" style={{ justifyContent: "flex-start" }}>
                                            <a href={config.url_facebook} target="_blank">
                                                <span className="elementor-icon-list-icon" style={{ color: "#FFFFFF", paddingRight: "5px" }}>
                                                    <i aria-hidden="true" className="fa-brands fa-facebook"></i>
                                                </span>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>Facebook</span>
                                            </a>
                                        </li>
                                        <li className="elementor-icon-list-item" style={{ justifyContent: "flex-start" }}>
                                            <a href={config.url_instagram} target="_blank">
                                                <span className="elementor-icon-list-icon" style={{ color: "#FFFFFF", paddingRight: "5px" }}>
                                                    <i aria-hidden="true" className="fa-brands fa-instagram"></i>
                                                </span>
                                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>Instagram</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-2 col-12 col-sm-12 col-lg-12 centradomobile w22pc">
                            <div className="pb-2 elementor-element elementor-element-895f683 elementor-widget elementor-widget-text-editor margB-600" data-id="895f683" data-element_type="widget" data-widget_type="text-editor.default" style={{ marginBottom: "22px" }}>
                                <div className="elementor-widget-container">
                                    <strong><span className="colorcorporativo footer-titles" style={{ textTransform: "uppercase", fontWeight: 700 }}>{t("Newsletter")}</span></strong>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-9b6e95f elementor-widget elementor-widget-text-editor" data-id="9b6e95f" data-element_type="widget" data-widget_type="text-editor.default">
                                <div className="elementor-widget-container" style={{ paddingBottom: "15px" }}>
                                    <span style={{ fontWeight: "normal", lineHeight: "25px", fontSize: "12px" }}>{t('Subscríbete a nuestra newsletter para recibir ofertas y eventos de Sevilla')}</span>
                                </div>
                            </div>
                            <div className="elementor-element elementor-element-e8594d9 NEWSLETTER pp-gravity-form-button-custom pp-gravity-form-pagination-buttons-auto elementor-widget elementor-widget-pp-gravity-forms" data-id="e8594d9" data-element_type="widget" data-widget_type="pp-gravity-forms.default">
                                <div className="elementor-widget-container">
                                    <div className="pp-contact-form pp-gravity-form">
                                        <div className="gf_browser_unknown gform_wrapper gravity-theme gform-theme--no-framework gf_stylespro_wrapper sp_inherit_wrapper gf_footer_full_wrapper" data-form-theme="gravity-theme" data-form-index="0" id="gform_wrapper_20">
                                            <div id="gf_20" className="gform_anchor" tabIndex="-1"></div>
                                            <div className="gform_heading">
                                                <p className="gform_description"></p>
                                            </div>
                                            <form id="gform_20" className=" form-mailchimp gf_stylespro sp_inherit gf_footer_full" action="" data-formid="20" autoComplete="off" onSubmit={enviaMailchmp}>
                                                <div className="gform-body gform_body">
                                                    <div id="gform_fields_20" className="gform_fields top_label form_sublabel_below description_below">
                                                        <div id="field_20_4" className="gfield gfield--type-email field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible" data-js-reload="field_20_4">
                                                            <label className="gfield_label gform-field-label" htmlFor="input_20_4" style={{ display: "none" }}>{t('Email')}</label>
                                                            <div className="ginput_container ginput_container_email marginmobile">
                                                                <input name="email" id="input_20_4" type="text" defaultValue="" className="large p10-600" placeholder={t('EMAIL')} aria-invalid="false" style={{ color: "#000" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="gform_footer top_label marginmobile">
                                                    <input type="submit" id="gform_submit_button_20" className="gform_button button gform-button--width-full bgcolorcorporativo" style={{ textTransform: "uppercase", color: "#FFF" }} value={t('Enviar')} />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <hr style={{ color: "white", opacity: "1" }} className="mt-5 hr-600" />
                        </div>
                        <div className="col-md-12 solodesktopfooter" style={{ paddingBottom: "10px" }}>
                            <section className="elementor-section elementor-top-section elementor-element elementor-element-195ac57 elementor-section-stretched elementor-hidden-mobile elementor-hidden-tablet elementor-hidden-tablet_extra elementor-hidden-mobile_extra elementor-section-boxed elementor-section-height-default">
                                <div style={{ float: "left", fontFamily: "'Lato', Sans-serif", fontSize: "12px" }}>
                                    <a href={"/" + preidioma + t('legal')} style={{ marginRight: "10px" }}>
                                        <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{t('Aviso legal, privacidad y cookies')}</span>
                                    </a>

                                    <a href={"/" + preidioma + t('terminos')}>
                                        <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{t('Términos y condiciones')}</span>
                                    </a>
                                </div>
                                <div style={{ float: "right" }}>
                                    <h6><span style={{ color: "#FFFFFF", fontFamily: "'Lato', Sans-serif", fontSize: "12px" }}>{config.nombre_comercial + " " + new Date().getFullYear() + " / " + t("Gestión apartamentos turísticos")}</span></h6>
                                </div>
                            </section>
                        </div>
                        <div className="col-md-12 solomobilefooter centradomobile">

                            <a className="anchorfooter" href={"/" + preidioma + t('legal')}>
                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{t('Aviso legal, privacidad y cookies')}</span>
                            </a>
                            <br />
                            <br />
                            <a className="anchorfooter" href={"/" + preidioma + t('terminos')}>
                                <span className="elementor-icon-list-text" style={{ color: "#FFFFFF" }}>{t('Términos y condiciones')}</span>
                            </a>
                            <br />
                            <br />
                            <span style={{ color: "#FFFFFF", fontFamily: "'Lato', Sans-serif", fontSize: "12px" }}>
                                {config.nombre_comercial + " " + new Date().getFullYear() + " " + config.nif_fiscal}
                                <br />
                                {t("Gestión apartamentos turísticos")}
                            </span>
                            <br />
                            <br />
                        </div>
                    </div>
                </Container>
            </footer>
            <div className="container marginB-600 pB-600">
                <div className="pt-2 pb-3 mb-4 bg-white row justify-content-center">
                    <div className="col-md-12" style={{ textAlign: "center" }}>
                        {idiomas.forEach((unidioma, index) => (
                            results = results.concat(
                                <span className='wpml-ls-slot-footer wpml-ls-item wpml-ls-item-{ unidioma.codigo } wpml-ls-item-legacy-list-horizontal' key={"i" + index} style={{ display: "inline-block", verticalAlign: "middle", padding: "0", margin: "0", listStyleType: "none" }}>
                                    <a className='wpml-ls-link' style={{ fontFamily: '"Lato", Sans-serif', marginRight: "10px" }} onClick={() => handleLangSelect(unidioma.codigo)}>
                                        <span className='wpml-ls-native'>{unidioma.nombre}</span>
                                    </a>
                                </span>
                            )
                        ))}
                        {results}
                    </div>
                </div>
            </div>
            {alert.show && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: "50"
                    }}>
                        <CustomAlert type={alert.type} message={alert.message} onClose={handleCloseAlert} />
                    </div>
                </div>
            )}
        </>
    );
}

export default Footer;
