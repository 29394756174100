import { React, useState } from "react";
import { useTranslation } from 'react-i18next';
import CustomAlert from "../Compartido/CustomAlert";

import axios from 'axios';

import "./../../../../../css/magno/contacto.css";
import "./../../../../../css/magno/elementor.css";
import "./../../../../../css/magno/gform.css";

const FormularioContacto = ({ lang, config }) => {

    const [alert, setAlert] = useState({ show: false, type: '', message: '' });

    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    const enviaContacto = (e) => {
        e.preventDefault()
        let token = document.getElementById('meta_token').getAttribute('content');

        const result = axios.post('/enviarcontacto', {
            yourname: e.target.name.value,
            contactEmail: e.target.email.value,
            contactMessage: e.target.message.value,
        }, {
            headers: {
                'content-type': 'text/json',
                'X-CSRF-TOKEN': token
            }
        })
            .then((response) => {
                if (response.data.type == 'success') {
                    setAlert({ show: true, type: 'ok', message: t("Contacto enviado. En breve nos pondremos en contacto con Ud.") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);

                    location.reload();
                }
                else {
                    setAlert({ show: true, type: 'ko', message: t("Contacto no enviado") });
                    setTimeout(() => setAlert({ show: false, type: '', message: '' }), 2000);
                }
            });
    }

    const handleCloseAlert = () => {
        setAlert({ show: false, type: '', message: '' });
    };

    return (
        <>
            <div className="espaicontacte">
                <div style={{ width: "100%", overflow: "hidden" }} className="fonscontacte">
                    <div className="fondocontacto" style={{ backgroundImage: 'url(images/web/magno/defhome.jpg)', backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", transformOrigin: "center" }}
                    ></div>
                </div>
                <div className="container caixa-contact">
                    <section>
                        <div className="elementor-container elementor-column-gap-default" style={{ zIndex: 1 }}>
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-456ec61">
                                <div className="elementor-widget-wrap elementor-element-populated">
                                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-cef10ba elementor-section-content-middle elementor-section-boxed elementor-section-height-default" data-id="cef10ba" data-element_type="section">
                                        <div className="elementor-container elementor-column-gap-default" style={{ zIndex: 1 }}>
                                            <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-abb8f4a" data-id="abb8f4a" data-element_type="column">
                                                <div className="elementor-widget-wrap elementor-element-populated">
                                                    <div className="elementor-element elementor-element-475425c pp-gravity-form-button-custom pp-gravity-form-pagination-buttons-auto elementor-widget elementor-widget-pp-gravity-forms" data-id="475425c" data-element_type="widget" data-widget_type="pp-gravity-forms.default">
                                                        <div className="elementor-widget-container contenedorcontacto" style={{ marginTop: "10%" }}>
                                                            <div className="pp-contact-form pp-gravity-form">
                                                                <div className="gf_browser_unknown gform_wrapper gravity-theme gform-theme--no-framework gf_stylespro_wrapper sp_inherit_wrapper gf_footer_spread_wrapper" data-form-theme="gravity-theme" data-form-index="0" id="gform_wrapper_19">
                                                                    <div id="gf_19" className="gform_anchor" tabIndex={-1}>
                                                                        <div className="gform_heading">
                                                                            <p className="gform_description"></p>
                                                                        </div>
                                                                    </div>
                                                                    <form method="post" id="contactForm" className=" gf_stylespro sp_inherit gf_footer_spread positionForm" action="sendcontact" data-formid="19" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)", backgroundColor: "00000096", color: "#FFF" }} onSubmit={enviaContacto}>
                                                                        <div className="gform-body gform_body">
                                                                            <div id="gform_fields_19" className="gform_fields top_label form_sublabel_below description_below">
                                                                                <div id="field_19_12" className="gfield gfield--type-html gfield--width-full TITULAR4 gfield_html gfield_html_formatted gfield_no_follows_desc field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible" data-js-reload="field_19_12" style={{ paddingTop: "15px" }}>
                                                                                    <span style={{ color: '#ffffff', fontFamily: 'Lato, sans-serif', fontWeight: 300, textAlign: 'center', fontSize: '20px', lineHeight: '25px' }}>{t('¿En qué podemos ayudarte?')}</span>
                                                                                </div>

                                                                                <div id="field_19_1" className="gfield gfield--type-text gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible o_medium" data-field-classname="o_medium" data-js-reload="field_19_1">
                                                                                    <label className="gfield_label gform-field-label mayuscula" htmlFor="input_19_1">{t('Nombre')}
                                                                                        <span className="gfield_required">
                                                                                            <span className="gfield_required gfield_required_text">({t('Obligatorio')})</span>
                                                                                        </span>
                                                                                    </label>
                                                                                    <div className="ginput_container ginput_container_text">
                                                                                        <input name="name" id="input_19_1" type="text" defaultValue="" className="large" placeholder={t('Nombre').toUpperCase() + " *"} aria-required="true" required aria-invalid="false" style={{ lineHeight: "3", color: "#000" }} />
                                                                                    </div>
                                                                                </div>

                                                                                <div id="field_19_3" className="gfield gfield--type-email gfield--width-full gfield_contains_required field_sublabel_below gfield--no-description field_description_below hidden_label gfield_visibility_visible" data-js-reload="field_19_3">
                                                                                    <label className="gfield_label gform-field-label" htmlFor="input_19_3">{t('Email')}
                                                                                        <span className="gfield_required">
                                                                                            <span className="gfield_required gfield_required_text">({t('Obligatorio')})</span>
                                                                                        </span>
                                                                                    </label>
                                                                                    <div className="ginput_container ginput_container_email">
                                                                                        <input name="email" id="input_19_3" type="text" defaultValue="" className="large" placeholder={t('Email').toUpperCase() + "*"} aria-invalid="false" required style={{ lineHeight: "3", color: "#000" }} />
                                                                                    </div>
                                                                                </div>

                                                                                <div id="field_19_13" className="gfield gfield--type-textarea gfield--width-full field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible" data-js-reload="field_19_13">
                                                                                    <label className="gfield_label gform-field-label screen-reader-text" htmlFor="input_19_13">{t('Mensaje') + " *"}</label>
                                                                                    <div className="ginput_container ginput_container_textarea">
                                                                                        <textarea name="message" id="input_19_13" className="textarea small" placeholder={t('Mensaje').toUpperCase() + " *"} aria-invalid="false" required rows="10" cols="50" style={{ lineHeight: "1", color: "#000" }}></textarea>
                                                                                    </div>
                                                                                </div>

                                                                                <fieldset id="field_19_8" className="gfield gfield--type-consent gfield--type-choice gfield--input-type-consent gfield--width-full field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible" data-js-reload="field_19_8">
                                                                                    <legend className="gfield_label gform-field-label screen-reader-text gfield_label_before_complex"></legend>
                                                                                    <div className="ginput_container ginput_container_consent">
                                                                                        <input name="aceptacondiciones" id="input_19_8_1" type="checkbox" value="1" aria-invalid="false" required />
                                                                                        <label className="gform-field-label gform-field-label--type-inline gfield_consent_label" htmlFor="input_19_8_1">{t('Estoy de acuerdo con la')} <a href={"/" + preidioma + t('legal')}> {t('Politica de privacidad y política de cookies')}</a>
                                                                                        </label>
                                                                                        <input type="hidden" name="input_8.2" value="Estoy de acuerdo" className="gform_hidden" />
                                                                                        <input type="hidden" name="input_8.3" value="11" className="gform_hidden" />
                                                                                    </div>
                                                                                </fieldset>

                                                                                <div id="field_19_10" className="mb-3 gfield gfield--type-html gfield--width-full form_contacta gfield_html gfield_html_formatted gfield_no_follows_desc field_sublabel_below gfield--no-description field_description_below gfield_visibility_visible" data-js-reload="field_19_10">
                                                                                    * {t('Todos los campos * son obligatorios')}<br />{t('Responsable: Magno Apartments. · Finalidad: Solicitud de contacto. · Legitimación: Consentimiento del interesado. · Destinatarios: No se ceden o comunican datos a terceros para prestar este servicio. · Derechos: A acceder rectificar y suprimir los datos.')}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="gform_footer top_label">
                                                                            <input type="submit" id="gform_submit_button_19" className="submitButton gform_button button mayusculacss" value={t('Enviar')} style={{ fontSize: "13px", marginBottom: "17px" }} />
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {alert.show && (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: "50"
                    }}>
                        <CustomAlert type={alert.type} message={alert.message} onClose={handleCloseAlert} />
                    </div>
                </div>
            )}
        </>

    );
}

export default FormularioContacto;
