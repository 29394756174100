import { React, useEffect } from "react";
import { Container, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import LangSelector from './LangSelector.jsx';
import MyNavDropdown from './MyNavDropdown.jsx';
import MyLangDropdown from './MyLangDropdown.jsx';

import { Helmet } from "react-helmet"

import "./../../../../../css/magno/menu.css";
import "./../../../../../css/magno/elementor.css";
import "./../../../../../css/magno/ubermenu.css";



const Menu = ({ idiomas, lang, config, config2, ruta, meta_titulo, meta_descripcion, meta_keywords }) => {
    const { t } = useTranslation();

    var preidioma = ""
    if (lang != 'es') preidioma = lang + "/";

    useEffect(() => {
        document.title = meta_titulo
        let titleMeta = document.querySelector("meta[name='title']")
        titleMeta.setAttribute("content", meta_titulo)
        let descMeta = document.querySelector("meta[name='description']")
        descMeta.setAttribute("content", meta_descripcion)
        let keyMeta = document.querySelector("meta[name='keywords']")
        keyMeta.setAttribute("content", meta_keywords)

        let favicon = ruta + "/web/favicon/favicon.ico"
        let fav = document.querySelector("link[rel='icon']")
        fav.setAttribute("href", favicon)

        if (config.css_personalizado) {
            const linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.type = 'text/css';
            linkElement.href = config.css_personalizado;
            const headElement = document.head;
            headElement.appendChild(linkElement);
        }

    }, []);

    return (
        <>
            {
                (config.activa_analytics == 1) ?
                    <Helmet>
                        <script async src="https://www.googletagmanager.com/gtag/js?id={config.codigo_analytics}"></script>
                        <script>
                            {`
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){window && window.dataLayer && window.dataLayer.push(arguments)}
                                gtag('js', new Date())
                                gtag('config', `+ config.codigo_analitycs + `);
                            `}
                        </script>
                    </Helmet>
                    :
                    ""
            }

            {/* -- Google tag (gtag.js) --*/}

            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-MTDLLG5Q0X"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){window && window.dataLayer && window.dataLayer.push(arguments)}
                        gtag('js', new Date());
                        gtag('config', 'G-MTDLLG5Q0X');
                    `}
                </script>
            </Helmet>

            {/*-- Start cookieyes banner magnoapartments.com--*/}
            <Helmet>
                <script id="cookieyes" type="text/javascript" src="https://cdn-cookieyes.com/client_data/09dfaf56b0667640d2d42d07/script.js"></script>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css?family=Lato%3A300%2C400%2C700&ver=6.1.1" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Roboto%3A%2C300%2C400%2C700&ver=6.1.1" rel="stylesheet"></link>
            </Helmet>
            {/*-- End cookieyes banner --*/}

            <Helmet>
                <script type="text/javascript">
                    {`
                        (function() {
                        window.sib ={equeue:[],client_key:"sb7u1ty7odnlhvmzea1f6lgl"};
                        window.sib.email_id = "";

                        window.sendinblue = {};
                        for (var j = ['track', 'identify', 'trackLink', 'page'], i = 0;i < j.length;i++) {
                            (function(k) {
                                window.sendinblue[k] = function() {
                                var arg = Array.prototype.slice.call(arguments);
                                (window.sib[k] || function() {
                                    var t = {};
                                    t[k] = arg;
                                    window.sib.equeue.push(t);
                                })(arg[0], arg[1], arg[2]);
                                };
                            })(j[i]);
                        }
                        var n = document.createElement("script"),i = document.getElementsByTagName("script")[0];
                        n.type = "text/javascript", n.id = "sendinblue-js", n.async = !0, n.src = "https://sibautomation.com/sa.js?plugin=wordpress&key=" + window.sib.client_key, i.parentNode.insertBefore(n, i), window.sendinblue.page();
                        })();
                    `}
                </script>
            </Helmet>

            <Helmet>
                <script>
                    {`
                        function loadScript(a){var b=document.getElementsByTagName("head")[0],c=document.createElement("script");c.type="text/javascript",c.src=https://tracker.metricool.com/resources/be.js,c.onreadystatechange=a,c.onload=a,b.appendChild(c)}loadScript(function(){beTracker.t({hash:"b3d17559fa4deaaa8bc53e68857bec34"})});
                    `}
                </script>
            </Helmet>

            <Navbar collapseOnSelect expand="lg" id="nd_options_header_6" className="sticky-top nd_options_section" data-bs-theme="dark" >
                <div className="nd_options_container nd_options_clearfix nd_options_position_relative nd_options_z_index_999">
                    <div className="elementor elementor-5346 elementor-motion-effects-parent" data-elementor-type="wp-page" data-elementor-id="5346">
                        <section className="menuDIv elementor-section elementor-top-section elementor-element elementor-element-18a841b elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-motion-effects-element elementor-sticky elementor-sticky--effects animated fadeInDown elementor-sticky--active elementor-section--handles-inside" data-settings='{"stretch_section":"section-stretched","background_background":"gradient","animation":"fadeInDown","animation_delay":50,"motion_fx_motion_fx_scrolling":"yes","sticky":"top","motion_fx_blur_effect":"yes","motion_fx_blur_level":{"unit":"px","size":11,"sizes":[]},"motion_fx_blur_direction":"out-in","motion_fx_blur_range":{"unit":"%","size":"","sizes":{"start":20,"end":80}},"motion_fx_devices":["desktop","tablet_extra","tablet","mobile_extra","mobile"],"sticky_on":["desktop","tablet_extra","tablet","mobile_extra","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'>
                            <div className="elementor-container elementor-column-gap-default no-maxWidth" style={{ marginRight: "0", marginLeft: '50px' }}>
                                <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-be55eff" style={{ marginLeft: "-200px", marginTop: "20px" }}>
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-40696cf elementor-widget elementor-widget-image" data-id="40696cf" data-element_type="widget" data-widget_type="image.default">
                                            <div className="elementor-widget-container">
                                                <Navbar.Brand href="/" className="logo logomagno attachment-full size-full wp-image-11224">
                                                    {config2.logo_fiscal != "" ? <img className="logo logomagno-custom attachment-full size-full wp-image-11224" src={ruta + "/web/logo/" + config2.logo_fiscal} sizes="(max-width: 516px) 100vw, 516px" /> : <img className="logo attachment-full size-full wp-image-11224" src="https://www.bedloop.com/imatges/logo.webp" sizes="(max-width: 516px) 100vw, 516px" />}
                                                </Navbar.Brand>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <Navbar.Collapse id="responsive-navbar-nav" className="menu shadowMenu" style={{ justifyContent: "end", marginRight: "-160px", padding: '10px 6px', gridGap: '24px', paddingTop: '0px' }}>
                                    <Nav className="me-auto-left">

                                        <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-720d61a elementor-hidden-mobile elementor-hidden-tablet_extra elementor-hidden-tablet elementor-hidden-mobile_extra" data-id="720d61a" data-element_type="column">
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div className="elementor-element elementor-element-c009618 elementor-widget elementor-widget-ubermenu" data-id="c009618" data-element_type="widget" data-widget_type="ubermenu.default">
                                                    <div className="elementor-widget-container" style={{ display: "flex" }}>
                                                        {/*<NavDropdown className="navegacio letrablanca ubermenu ubermenu-nojs ubermenu-main ubermenu-menu-27 ubermenu-responsive ubermenu-responsive-default ubermenu-responsive-collapse ubermenu-horizontal ubermenu-transition-fade ubermenu-trigger-hover_intent ubermenu-skin-minimal ubermenu-bar-align-right ubermenu-items-align-right ubermenu-bound-inner ubermenu-disable-submenu-scroll ubermenu-sub-indicators ubermenu-sub-indicators-align-text ubermenu-retractors-responsive ubermenu-submenu-indicator-closes" title={ t("Contactar") } id="collasible-nav-dropdown.ayuda ubermenu-main-27s" style={{ marginTop:"2px" }}>
                              <Container className="mt-1 mb-1 ajuda">
                                <Row className="ml-5 ubermenu-nav" data-title="MAGNO" id="ubermenu-nav-main-27">
                                  <Col xs="12" md="12" sm="6" className="mt-2 mb-3 ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-14448 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto">
                                   <span className="bold text-box-contactar" style={{ paddingLeft: "12px"}}><i className="fa-regular fa-comments"></i> { t("¿Alguna pregunta? ¡Hablemos!") }</span>
                                  </Col>
                                  <hr></hr>
                                  <Col xs="12" md="12" sm="6" className="text-box-contactar">
                                    <span style={{ display:"block", marginBottom:"6px" }}>
                                        <i className="fa-solid fa-phone"></i> { config.telefono_fiscal}
                                    </span>
                                     <span style={{ display:"block", marginBottom:"20px" }}>
                                        <a href={"/"+preidioma+t('contacto') } className="text-box-contactar"><i className="fa-regular fa-envelope" aria-hidden="true"></i> { config.email_fiscal}</a>
                                    </span>
                                  </Col>
                                </Row>
                              </Container>
                            </NavDropdown>*/}
                                                        <MyNavDropdown
                                                            config={config}
                                                            lang={lang}
                                                        />
                                                        <MyLangDropdown
                                                            idiomas={idiomas}
                                                            langactual={lang.toString().toUpperCase()}
                                                        />
                                                        {/*<LangSelector
                              idiomas = {idiomas}
                              langactual = { lang.toString().toUpperCase()}
                              style={{ color:"white" }}
                            />*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Nav>

                                    <Nav.Link className="navegacio botocerca" href={"/" + preidioma + t("busca")}>{t("RESERVA")}</Nav.Link>

                                </Navbar.Collapse>
                            </div>
                        </section>
                    </div>
                </div>

            </Navbar>
        </>
    );
}

export default Menu;
