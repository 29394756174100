import Menu from './Compartido/Menu.jsx';
import DatosReserva from './Reserva/DatosReserva.jsx';
import Footer from './Compartido/Footer.jsx';

import { useTranslation } from 'react-i18next';

const Reserva = (props) => {

    let { t } = useTranslation();

    return (
        <main>
            <Menu
                idiomas = {props.idiomas}
                lang = {props.lang}
                config = {props.websgrupo}
                config2 = {props.config}
                ruta = {props.ruta}
                meta_titulo = {"Magno Apartments - "+t("Reservar")}
                meta_descripcion = {props.meta_descripcion}
                meta_keywords = {props.meta_keywords}
            />

            <DatosReserva
                propiedad = {props.propiedad}
                precios ={props.precios}
                llegada = {props.llegada}
                salida  = {props.salida}
                difdias = {props.difdias}
                adultos = {props.adultos}
                ninos = {props.ninos}
                bebes = {props.bebes}
                lang = {props.lang}
                ruta = {props.ruta}
                config = {props.config}
                tpvs = {props.tpvs}
                paypal = {props.paypal}
                transferencia = {props.transferencia}
                stripe = {props.stripe}
                moneda = {props.moneda}
                numpropiedades = {props.numpropiedades}
                websgrupo = {props.websgrupo}
            />

            <Footer
                 lang = {props.lang}
                 config = {props.websgrupo}
                 config2 = {props.config}
                 idiomas = {props.idiomas}
                 ruta = {props.ruta}
            />
        </main>
    );
}

export default Reserva;
